// extracted by mini-css-extract-plugin
export var aboutMe = "about-module--aboutMe--0b01c";
export var aboutMeWrapper = "about-module--aboutMeWrapper--ed81a";
export var bluesky = "about-module--bluesky--0f83d";
export var contact = "about-module--contact--6ecb5";
export var contactOuterWrapper = "about-module--contactOuterWrapper--26b9b";
export var contactWrapper = "about-module--contactWrapper--2a8dd";
export var github = "about-module--github--d9ec2";
export var heading = "about-module--heading--e5bb1";
export var hobbies = "about-module--hobbies--c0752";
export var languages = "about-module--languages--bfc8a";
export var legal = "about-module--legal--5ab94";
export var pronunciation = "about-module--pronunciation--c779f";
export var recipe = "about-module--recipe--8fa8d";
export var sidebar = "about-module--sidebar--a2a3b";
export var skills = "about-module--skills--40caf";
export var twitter = "about-module--twitter--7714e";